export default function TextBubble({ text }) {
    if (!text) return null;
  
    return (
      <div style={{
        position: 'absolute',
        bottom: '80px',
        left: '10px',
        backgroundColor: '#fff',
        color: '#000',
        padding: '10px 20px',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        maxWidth: '300px',
        zIndex: 600,
        wordWrap: 'break-word',
        fontSize: '16px',
      }}>
        {text}
      </div>
    );
  }

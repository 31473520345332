import React from 'react';

function MicrophoneStatus({ conversationActive, isListening }) {
  if (!conversationActive) {
    return null; // No renderizar nada si la conversación no está activa
  }

  const styles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: '10px 20px',
    borderRadius: '5px',
    color: '#fff',
    zIndex: 1000,
    backgroundColor: isListening ? 'green' : 'red',
  };

  return (
    <div style={styles}>
      {isListening ? 'Puedes hablar ahora' : 'Espera'}
    </div>
  );
}

export default MicrophoneStatus;